import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { routes } from 'src/app/consts/routes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  public routers: typeof routes = routes;
  @Output() sendLoginForm = new EventEmitter<void>();
  public form: FormGroup;
  public flatlogicEmail = 'admin@flatlogic.com';
  public flatlogicPassword = 'admin';
  submitted: boolean;
  loading: boolean;
  returnUrl: any;
  error: any;
  return_url = '/dashboard';
  errors: any;
  userData;
  userArray: any;

  constructor(private spinner: NgxSpinnerService, private toastr: ToastrService, private httpClient: HttpClient,private router: Router, 
    private route: ActivatedRoute,){
    const token = localStorage.getItem('ingpAccessToken');
      if (token) {
        this.userData = localStorage.getItem('ingpUserDetails');
        this.userArray = JSON.parse(this.userData);
        console.log(this.userArray);
         if(!this.userArray.roles.data.length){
          this.router.navigate([this.routers.PROFILE]);
        } else {
          this.router.navigate([this.routers.DASHBOARD]);
        }
      }
  }

  public ngOnInit(): void {

    console.log('again');
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });

    this.route.queryParams
    .subscribe(res => {
      if (res["returnUrl"])
        this.return_url = res["returnUrl"];
    })
  }

  public login(): void {
    this.spinner.show();
    this.submitted = true;
    this.errors = '';
    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }
    this.loading = true;
    
    let username = this.form.value.email;
    let password = this.form.value.password;
    let grant_type = environment.grant_type;
    let client_id = environment.client_id;
    let client_secret = environment.client_secret;
    // this.api_service.HttpPost('/oauth/token', { grant_type, client_id, client_secret_id,username, password}, httpOptions );
    this.httpClient.post<any>(`${environment.apiUrl}/oauth/token`, { grant_type, client_id, client_secret,username, password}  ).subscribe(
      res => {
        localStorage.setItem('ingpAccessToken', res.access_token);
        localStorage.setItem('ingpRefreshToken', res.refresh_token);
        const headerDict = {
          'Authorization': `Bearer ${res.access_token}`,
        }
        const requestOptions = {                                                                                                                                                                                 
          headers: new HttpHeaders(headerDict), 
        };
        
        this.httpClient.get<any>(`${environment.apiUrl}/api/me`, requestOptions).subscribe(
          result => {
            console.log('result');
            console.log(result);
            localStorage.setItem('ingpUserDetails', JSON.stringify(result.data)); 
            if(!result.data.roles.data.length){
              this.router.navigate([this.routers.PROFILE]);
            } else {
              this.router.navigate([this.routers.DASHBOARD]);
            }
          },
           err => {
    this.spinner.hide();

            console.log(err + " from user get details");
          }
        );

        this.toastr.success(
          'Login Successfull'
        );
      },
      err => {
    this.spinner.hide();

            // this.errors = err.error.error;
            this.toastr.error(
              'Login failed, check the details'
            );
      }
  )

  }
}
