import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    return_url = '/login';
    constructor(private toastr: ToastrService, public route:Router, private router: ActivatedRoute) {
        this.router.queryParams
        .subscribe(res => {
          if (res["returnUrl"])
            this.return_url = res["returnUrl"];
        });
      }
     
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
          if ([401].indexOf(err.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                localStorage.removeItem('ingpAccessToken');
                this.toastr.error(
                  'Token Expired or Unauthorized access! Login again'
                );
                this.route.navigateByUrl(this.return_url);
                return EMPTY;
            }
            return throwError(err);
        }))
    }
}