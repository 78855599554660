import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { routes } from 'src/app/consts/routes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-form',
  templateUrl: './sign-form.component.html',
  styleUrls: ['./sign-form.component.scss']
})
export class SignFormComponent implements OnInit {
  @Output() sendSignForm = new EventEmitter<void>();
  public routers: typeof routes = routes;
  public form: FormGroup;
  submitted: boolean;
  errors: string;
  loading: boolean;
  success: string;
  constructor(private spinner: NgxSpinnerService, private toastr: ToastrService, private httpClient: HttpClient, private router: Router){
    
  }

  public ngOnInit(): void {

    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      password_confirmation: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
  }

  get f(){
    return this.form.controls;
  }

  public sign(): void {
    this.spinner.show();
    console.log('in');
    this.submitted = true;
    this.errors = '';
    this.success = '';
    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }
    this.loading = true;

    this.httpClient.post<any>(`${environment.apiUrl}/api/register`, this.form.value  ).subscribe(
      res => {
    this.spinner.hide();

        console.log('done');
        this.router.navigateByUrl('login');
        // this.success = 'Data Created Successfully';
        this.toastr.success(
          'Registered Successfull'
        );
        this.router.navigate([this.routers.DASHBOARD]);
        this.form.reset();
      },
      err => {
    this.spinner.hide();

        console.log(err)
        if(err.error.errors.email){
          this.toastr.error(
            err.error.errors.email[0]
          );
        } else if(err.error.errors.password) {
          this.toastr.error(
            err.error.errors.password[0]
          );
        } else {
          this.toastr.error(
            'Registration failed! try again'
          );
        }
      }
    )
  }
  get password() {
    return this.form.get('password');
  }
  get password_confirmation() {
    return this.form.get('password_confirmation');
  } 
}


