import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AuthService } from 'src/app/pages/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { routes } from '../../consts/routes';
import { permission } from '../../consts/permission';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public routes: typeof routes = routes;
  public permission: typeof permission = permission;
  public isOpenUiElements = false;
  userDetailsArray: string;
  accessPermission: any;
  currentAccess = ['0'];

  constructor(private httpClient: HttpClient, public authService: AuthService){
    // this.userDetailsArray = this.authService.getUserDetails();
    this.httpClient.get<any>(`${environment.apiUrl}/api/me`).subscribe(
      result => {
        // console.log(result.data.roles.data[0].permissions.data)
        if(result.data.roles.data.length){
          if(result.data.roles.data[0].permissions.data){
            let accessPermission = result.data.roles.data[0].permissions.data;
            let currentAccess;
            accessPermission.forEach(element => {
              console.log(element.name);
              this.currentAccess.push(element.name);
            });
          }
        }
      },
       err => {
        console.log(err + " from user get details");
      }
    );
  }

  public openUiElements() {
    this.isOpenUiElements = !this.isOpenUiElements;
  }
}
