import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { routes } from '../../../../consts';
import { User } from '../../../../pages/auth/models';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  @Input() user;
  @Output() signOut: EventEmitter<void> = new EventEmitter<void>();
  public routes: typeof routes = routes;
  public flatlogicEmail: string = "https://flatlogic.com";

  constructor(private router: Router){

  }
  
  public signOutEmit(): void {
    localStorage.removeItem('ingpAccessToken');
    this.router.navigate(['login']);
  }

  goToProfile() {
    this.router.navigate(['profile']);
  }
}
