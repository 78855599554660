<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-fussion" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>


<div class="auth-page">
  <div class="auth-page__content-block">
    <div class="auth-page__content-wrapper">
      <mat-tab-group class="auth-page__group">
        <mat-tab label="Login">
          <!-- <h4 class="auth-page__group-title">Login</h4> -->
          <div class="auth-page__google-button-wrapper">
            <!-- <button mat-raised-button class="auth-page__google-button">
              <img class="auth-page__google-button-icon" src="./assets/auth/google.svg" alt="google">Sign in with Google
            </button> -->
          </div>

          <!-- <div class="auth-page__border-wrapper">
            <div class="auth-page__border-line"></div>
            <p class="auth-page__border-title">or</p>
            <div class="auth-page__border-line"></div>
          </div> -->

          <app-login-form (sendLoginForm)="sendLoginForm()"></app-login-form>

        </mat-tab>
        <mat-tab label="New User">
          <!-- <h4 class="auth-page__group-title">Welcome!</h4> -->
          <p class="auth-page__group-sub-title">Create your account</p>

          <app-sign-form (sendSignForm)="sendSignForm()"></app-sign-form>

          <!-- <div class="auth-page__border-wrapper">
            <div class="auth-page__border-line"></div>
            <p class="auth-page__border-title">or</p>
            <div class="auth-page__border-line"></div>
          </div> -->

          <div class="auth-page__google-button-wrapper">
            <!-- <button mat-raised-button class="auth-page__google-button">
              <img class="auth-page__google-button-icon" src="./assets/auth/google.svg" alt="google">Sign in with Google
            </button> -->
          </div>
        </mat-tab>
      </mat-tab-group>

      <!-- <p class="auth-page__footer-title">© 2014-{{todayDate | year}} <a href="https://flatlogic.com/">Flatlogic</a>, LLC. All rights reserved.</p> -->
    </div>
  </div>
  <div class="auth-page__logo">
    <div class="auth-page__logo-wrapper">
      <img class="auth-page__profile-img" src="./assets/auth/Img1.png" alt="logo">
      <img class="auth-page__logo-img" src="./assets/auth/INGP-logo-New.png" alt="logo">
      <!-- <h6 class="auth-page__logo-title">INGP Login</h6> -->
    </div>
  </div>
</div>
