  <div *ngIf="errors" style="margin-bottom: 20px" class="grant">{{errors}}</div>
  <div *ngIf="success" style="margin-bottom: 20px" class="grant">{{success}}</div>
  <form class="form" [formGroup]="form" (ngSubmit)="sign()">

  <mat-form-field style="margin-bottom: 10px;" class="form__input" appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput placeholder="Name" formControlName="name">
  </mat-form-field>

  <mat-form-field style="margin-bottom: 10px;" class="form__input" appearance="outline">
    <mat-label>Email Address</mat-label>
    <input matInput placeholder="Email Address" type="email" formControlName="email">
  </mat-form-field>

  <mat-form-field style="margin-bottom: 10px;" class="form__input" appearance="outline">
    <mat-label>Password</mat-label>
    <input matInput placeholder="Password" type="password" formControlName="password">
    <!-- <mat-error [hidden]="!password.errors.minlength"  [hidden]="!password.errors.minlength">Minimum length is 8</mat-error> -->
  </mat-form-field>

  <mat-form-field class="form__input" appearance="outline">
    <mat-label>Confirm</mat-label>
    <input matInput placeholder="Confirm" type="password" formControlName="password_confirmation">
    <!-- <mat-error [hidden]="!password_confirmation.errors.minlength">Minimum length is 8</mat-error> -->
  </mat-form-field>

  <div class="form-actions">
    <button class="form-actions__create" mat-raised-button color="primary" type="submit">Create your account</button>
  </div>
</form>
