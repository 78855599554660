export enum routes {
  DASHBOARD = '/dashboard',
  MEMBERS = '/members',
  MEMBERVIEW = '/members/view/',
  DOWNLOAD = '/download',
  PROFILE = '/profile',
  VIEW = '/view',
  TYPOGRAPHY = '/typography',
  TABLES = '/tables',
  NOTIFICATION = '/notification',
  UI_ELEMENTS_ICONS = '/ui/icons',
  UI_ELEMENTS_CHARTS = '/ui/charts',
  UI_ELEMENTS_MAP = '/ui/map',
  LOGIN = '/login'
}
