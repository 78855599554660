import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userToken = localStorage.getItem('ingpAccessToken');
    const modifiedReq = req.clone({ 
      // headers: req.headers.set('Access-Control-Allow-Origin','*')
      // .set('Authorization', `Bearer ${userToken}`)
      setHeaders: {
        Authorization: `Bearer ${userToken}`,
        // 'Access-Control-Allow-Methods': 'GET,HEAD,POST,PUT,DELETE,OPTIONS'
    }
    });
    return next.handle(modifiedReq);
  }
}