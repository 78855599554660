<div *ngIf="errors" class="grant">{{errors}}</div>
<form class="form" [formGroup]="form" (ngSubmit)="login()">
  <mat-form-field class="form__input" appearance="outline">
    <mat-label>Email Address</mat-label>
    <input matInput placeholder="Email Address" type="email" formControlName="email">
  </mat-form-field>

  <mat-form-field class="form__input" appearance="outline">
    <mat-label>Password</mat-label>
    <input matInput placeholder="Password" type="password" formControlName="password">
  </mat-form-field>

  <div class="form-actions">
    <button class="form-actions__login" mat-raised-button color="primary" type="submit">Login</button>
    <button class="form-actions__forget" mat-button>Forget password</button>
  </div>
</form>
